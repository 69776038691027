<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="rows layout-column">
        <div class="layout-row row-1 layout-align-center-center">
          <section-title
            :partNo="part.id"
            :partTitle="part.title"
            data-depth="2.5"
            class="text-right parallax-item parallax-relative single-line"
            :show="showTitle"
          ></section-title>
        </div>
        <div class="layout-row flex-grow row-2">
          <div class="col col-1 layout-column layout-align-start-end">
            <lead
              :show="showLead"
              class="parallax-item parallax-relative"
              data-depth="2"
              >{{ data.meta.description }}</lead
            >

            <div class="layout-column image-video layout-align-start-end">
              <heading
                class="parallax-item parallax-relative section-heading"
                data-depth="1.8"
                :show="showMisc"
                >The Donut Economy</heading
              >

              <div class="layout-row layout-align-start-start">
                <video-box
                  id="donut-video"
                  class="parallax-item section-videobox parallax-relative"
                  data-depth="2.5"
                  :show="showVideoBox"
                  :data="data.boxes.video"
                  :timescale="1"
                >
                </video-box>
                <image-box
                  id="donut-diagram"
                  :show="showImageBox"
                  class="parallax-item parallax-relative section-imagebox"
                  data-depth="2"
                  :data="data.boxes.image"
                  :captionAlign="'left'"
                >
                </image-box>
              </div>
            </div>
          </div>
          <div class="col col-2 layout-align-start-start layout-column">
            <ink
              class="absolute"
              :show="showInk"
              :image="data.images.ink"
              :parallaxDepth="1"
              :customTimeline="inkTimeline"
            ></ink>
            <diagram-donut
              :show="showDiagram"
              :controls="data.controls"
              :wink="parallaxActive"
            ></diagram-donut>
          </div>
        </div>
      </div>
    </div>
    <modal-lightbox></modal-lightbox>
    <modal-video></modal-video>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import Lead from "@/components/ui/Lead.vue";
import Heading from "@/components/ui/Heading.vue";
import Ink from "@/components/ui/Ink.vue";
import ModalLightbox from "@/components/ui/ModalLightbox.vue";
import ImageBox from "@/components/ui/ImageBox.vue";
import ModalVideo from "@/components/ui/ModalVideo.vue";
import VideoBox from "@/components/ui/VideoBox.vue";
import DiagramDonut from "@/views/chapters/1/DiagramDonut.vue";

export default {
  name: "Chapter-1-4",
  mixins: [chapterPart],
  components: {
    SectionTitle,
    Lead,
    Heading,
    Ink,
    ModalLightbox,
    ModalVideo,
    ImageBox,
    VideoBox,
    DiagramDonut,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 300],
        ["showTitle", 600],
        ["showDiagram", 0],
        ["showInk", 0],
        ["showImageBox", 400],
        ["showVideoBox", 200],
        ["showLead", 200],
        ["showMisc", 900],
      ],
      inkTimeline: {
        duration: 1.7,
        animations: {
          ease: Back.easeOut.config(1),
          scale: 0.5,
          rotation: 45,
        },
      },
    };
  },
};
</script>

<style scoped>
.view-part {
  background: url(https://media.londolozi.com/africa/diagrams/part-1-4.png) top
    center no-repeat;
  background: none;
}
.col-1 {
  margin: 1.4rem 0 0 0;
}
.col-2 {
  max-width: 49%;
  padding-left: 3%;
}
.section-ink {
  opacity: 0.65;
  left: -4rem !important;
  top: -12rem !important;
  max-width: 100%;
}
/deep/ .section-ink img {
  max-width: 974px;
  width: 130%;
}
.section-title {
  margin: 0.65em 17% 0.4em 0;
}
.part-lead {
  max-width: 43rem;
}
.image-video {
  padding: 0 5.4% 0 0;
  width: 100%;
  position: relative;
}
.section-heading {
  margin: 2.9rem 0 0 27%;
  align-self: flex-start;
}
.section-videobox {
  margin-right: -1.5rem;
  z-index: 2;
  margin-top: 2.1rem;
}
.section-imagebox {
  z-index: 1;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1600px) {
  .image-video {
    padding-right: 0;
  }
  .col-1 {
    max-width: 49%;
  }
  .col-2 {
    max-width: 100%;
    width: 100%;
  }
  .section-ink {
    left: -6rem !important;
    top: -12rem !important;
  }
}

@media screen and (max-width: 1440px) {
  .row-2 {
    padding: var(--col-padding);
  }
  .col-2 {
    padding-left: 2rem;
  }
  .col-1 {
    max-width: 48%;
  }
  .section-ink {
    left: -6rem !important;
    top: -12rem !important;
  }
  /deep/ .section-ink img {
    width: 136%;
  }
}

@media screen and (max-width: 1366px) {
  .section-videobox {
    margin-right: -6rem;
    margin-top: 1.5rem;
  }
  .col-1 {
    max-width: 49%;
    margin-top: 0;
  }
  .section-heading {
    margin-top: 2rem;
  }
  .section-ink {
    left: -5rem !important;
    top: -10rem !important;
  }
}

@media screen and (max-width: 1200px) {
  .section-ink {
    left: 52% !important;
    transform: translate(-50%, 0) !important;
    top: -60px !important;
    width: 968px;
    max-width: 968px;
    display: flex;
    flex: 1 0 968px;
    justify-content: center;
    align-items: center;
  }
  /deep/ .section-ink img {
    width: 100%;
    position: relative !important;
  }
  .row-2 {
    flex-direction: column;
  }
  .col-2 {
    padding-left: 0;
    padding-bottom: 2rem;
  }
  .row-2 > .col {
    width: 100%;
    max-width: 100%;
    align-items: center;
    flex: 0 1 auto;
  }
  .image-video {
    align-items: center;
  }
  .section-videobox {
    margin: 0.5rem 1rem 0 0;
  }
  #donut-diagram {
    margin-left: 0.5rem;
  }
  /deep/ .donut-diagram h3 {
    order: 1;
    margin: 2.8rem 0 3rem 4.3rem;
    align-self: flex-end;
  }
  /deep/ .diagram-wrap {
    order: 2;
  }
  .section-ink {
  }
  .section-title {
    margin: 0.378em 0 0.489em;
  }
  /deep/ .single-line.section-title > span {
    vertical-align: unset;
    margin: 0;
  }
  /deep/ .single-line.section-title br {
    display: block;
  }
  /deep/ .diagram-wrap {
    font-size: 16px;
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 991px) {
  .section-videobox {
    margin-right: -2.5rem;
    margin-top: 1.2rem;
  }
  #donut-diagram {
    margin-left: 0;
    margin-top: -0.5rem;
  }
}

@media screen and (max-width: 767px) {
  /deep/ .diagram-wrap {
    font-size: 15px;
  }
}
@media screen and (max-width: 712px) {
  .section-ink {
    max-width: 145%;
    bottom: -14vw !important;
    top: auto !important;
  }
  .section-videobox {
    margin-right: -2rem;
  }
  #donut-diagram {
    margin-left: -2rem;
  }
}

@media screen and (max-width: 639px) {
  .section-videobox {
    margin-right: 0;
    margin-top: 0.5rem;
  }
  .section-heading {
    margin-left: 0;
    align-self: flex-end;
  }
  #donut-diagram {
    margin-left: 0;
    padding-bottom: 2rem;
  }
  .image-video > .layout-row {
    flex-direction: column;
    align-items: center;
  }
  /deep/ .media-caption {
    left: 50%;
    top: 2.1em;
    padding: 0 !important;
    text-align: center;
  }
  /deep/ .donut-diagram h3 {
    margin-left: 0;
    margin-bottom: 2rem;
  }
  .section-ink {
    bottom: -13vw !important;
  }
  /deep/ .diagram-wrap {
    font-size: 13px;
  }
  /deep/ .donut-text {
    height: 102%;
    width: 102%;
    left: -1%;
    top: -1%;
  }
  .part-heading,
  .part-lead {
    margin-right: var(--col-padding-adjust);
  }
}

@media screen and (max-width: 520px) {
  /deep/ .diagram-wrap {
    font-size: 12px;
  }
}

@media screen and (max-width: 479px) {
  .section-ink {
    bottom: -12vw !important;
  }
  /deep/ .diagram-wrap {
    font-size: 10px;
  }
}

@media screen and (max-width: 420px) {
  /deep/ .diagram-wrap {
    font-size: 9.5px;
  }
}
</style>
