<template>
  <div class="part-component part-heading">
    <h3 class="normal" ref="text">
      <slot></slot>
    </h3>
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

export default {
  name: "Heading",
  mixins: [partComponent],
  props: {
    show: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    createTimeline() {
      const { text } = this.$refs;

      const tl = new TimelineMax({
        paused: true,
      });

      tl.from(
        text,
        0.6,
        {
          autoAlpha: 0,
          y: "+=30",
          ease: Power2.easeOut,
        },
        0
      );

      this.timeline = tl;
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 0;
  display: block;
}
</style>
